const registerBtn = document.querySelector(".register__form_button") || null;
const loginBtn = document.querySelector(".login__form_button") || null;
const checkBox = document.querySelectorAll('input[type="checkbox"].c-inputo');
const alreadyUser = document.querySelector(".already_user");
const alreadyUser2 = document.querySelector(".already_user2");
const inputEmail = document.querySelector("input#email");

const legalArr0 = document.querySelectorAll('input[name="legal00[]"]');
const legalArr1 = document.querySelectorAll('input[name="legal01[]"]');

const register_functions = {
    listeners: () => {
        registerBtn != null
            ? registerBtn.addEventListener(
                  "click",
                  register_functions.registerUser
              )
            : null;
        loginBtn != null
            ? loginBtn.addEventListener("click", register_functions.loginUser)
            : null;
        checkBox != null
            ? [...checkBox].forEach((check) => {
                  check.addEventListener(
                      "click",
                      register_functions.reseterCheck
                  );
              })
            : null;
    },
    registerUser: (e) => {
        let form_filled = true;
        const data = new FormData();
        const inputsCom = [...document.querySelectorAll(".c-input")];
        const listArr = {
            arr0:[],
            arr1:[],
        }
        inputsCom.forEach((input) => {
            if (input.id === "consent_data") {
                /*RESET REQUIRED CLASS IF EXIST*/ input.classList.contains(
                    "alert_required"
                )
                    ? input.classList.remove("alert_required")
                    : null;
                if (!input.checked) {
                    input.classList.add("alert_required");
                    form_filled = false;
                } else {
                    data.append(input.id, input.checked);
                }
            } else {
                const parentDom = input.parentElement;
                /*RESET REQUIRED CLASS IF EXIST*/ parentDom.classList.contains( "alert_required" ) ? parentDom.classList.remove("alert_required") : null;
                if (input.value !== "") {
                    data.append(input.id, input.value);
                } else {
                    if (input.hasAttribute("required")) {
                        parentDom.classList.add("alert_required");
                        form_filled = false;
                    }
                }
            }
        });
        legalArr0.forEach(inpt=>{
            if (inpt.checked) {
                listArr.arr0.push(inpt.value);
            }     
        });
        legalArr1.forEach((inpt) => {
            if (inpt.checked) {
                listArr.arr1.push(inpt.value);
            }
        });
        data.append("legal00", listArr.arr0);
        data.append("legal01", listArr.arr1);

        if (form_filled) {
            registerBtn.classList.contains( "alert_required" ) ? registerBtn.classList.remove("alert_required") : null;
            alreadyUser.classList.contains( "active" ) ? alreadyUser.classList.remove("active") : null;
            alreadyUser2.classList.contains( "active" ) ? alreadyUser2.classList.remove("active") : null;
            alreadyUser2.innerHTML = "";

            const fetchData = {
                method: "POST",
                body: data,
            };
            fetch("register", fetchData)
                .then((respond) => respond.json())
                .then((msg) => {
                    if (msg.status === "success") {
                        window.location.href = msg.redirect;
                    } else if (msg.status === "already") {
                        alreadyUser.classList.add("active");
                        inputEmail.classList.add("alert_required");
                    } else if (msg.status === "error") {
                        alreadyUser2.classList.add("active");
                        alreadyUser2.innerHTML = msg.message;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    alert('Error de servidor. Vuelve a intentarlo más tarde.');
                });
        } else {
            registerBtn.classList.add('alert_required');
        }
    },
    loginUser: (e) => {
        let form_filled = true;
        const data = new FormData();
        const inputsCom = [...document.querySelectorAll(".c-input")];
        inputsCom.forEach((input) => {
            if (input.value !== "") {
                data.append(input.id, input.value);
            } else {
                const parentDom = input.parentElement;
                /*RESET REQUIRED CLASS IF EXIST*/ parentDom.classList.contains( "alert_required" ) ? parentDom.classList.remove("alert_required") : null;
                if (input.value !== "") {
                    data.append(input.id, input.value);
                } else {
                    parentDom.classList.add("alert_required");
                    form_filled = false;
                }
                form_filled = false;
            }
        });
        if (form_filled) {
            const fetchData = {
                method: "POST",
                body: data,
            };
            fetch("login", fetchData)
                .then((respond) => respond.json())
                .then((msg) => {
                    if (msg.status === "success") {
                        window.location.href = msg.redirect;
                    } else if(msg.status = 'error')
                    {
                        alert('Credenciales erroneos');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
        }
    },
    reseterCheck:(e)=>{
        const elem = e.currentTarget;
        [...checkBox].forEach((check) => {
            if (elem === check){
                check.classList.contains("alert_required")
                    ? check.classList.remove("alert_required")
                    : null;
            }
                
        });
    },
};

const init = ()=>{
    register_functions.listeners();
};

init();
